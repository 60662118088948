<template>
     <a class="navbar-brand display-1 p-0" href="#" >
        <div class="row">
            <div class="col">
                <img src="@/assets/logo.svg" alt="" width="50" height="100" class="img-fluid align-text-top">
            </div>
            <div class="col-sm-auto">
                <h2 class="mt-3 text-dark">
                    Ingenieurbüro <br> Dr.Husmann
                </h2>
            </div>
        </div>
    </a>
</template>
<script>
export default{
    name: "LogoComponent",
    components: {
        
    }
}
</script>