<template>
     <div >
        <div class="p-3">
        <h3 class="display-3">{{ $t('platforms.title') }}</h3>
        <span>{{ $t('platforms.info') }}</span><br><br>
        <span class="display-5">{{ $t('platforms.subtitle') }} </span>
        <div class="container">
            <div class="row p-5">
              <PlatformSelectionLogo v-for="platform in platforms" :key="platform.id" :img="platform.image" :platformId="platform.id"></PlatformSelectionLogo>
            </div>
           
        </div>
        </div>
    </div>
    
</template>
<script>
import PlatformSelectionLogo from '@/components/PlatformSelectionLogo.vue';


export default{
    name: "PackagesLandingView",
    components:{
      PlatformSelectionLogo
    },
    created() {
        
    },
   
    computed: {
        platforms() {
            
            return this.$store.getters.platforms;
        }
    }
}
</script>
<style>



</style>