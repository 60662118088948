<template>
    <div>
        <button class="btn btn-lg btnCustom text-dark" @click="redirectToContact()">{{text}}</button>
    </div>
</template>
<script>
export default{
    name:"ContactComponent",
    props:{
        text: String
    },
    methods:{
        redirectToContact(){
            this.$router.push({ path: '/contact' }); 
        }
    }
}
</script>
<style>




</style>