<template>
    <div class="dark-text">
        <h3 class="display-3">{{ $t('dataprotection.title') }} </h3>
    </div>
    <DataProtectionDE v-if="isGerman"></DataProtectionDE>
    <DataProtectionEN v-else-if="isEnglish"></DataProtectionEN>
</template>
<script>
import DataProtectionDE from '@/components/DataProtectionDE.vue';
import DataProtectionEN from '@/components/DataProtectionEN.vue';
export default{
    name:"DataProtectionView.vue",
    components:{DataProtectionDE,DataProtectionEN},
    computed: {
        isGerman() {
            return this.$i18n.locale == 'de';
        },
        isEnglish() {
            return this.$i18n.locale == 'en';
        }
    }
}
</script>