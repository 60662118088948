
const messages = {
  en: {
    platforms:{
      title:"Packages",
      subtitle:"Which platform are you using?",
      info:"Regardless of individual solutions, we offer extensions for various platforms. We develop these solutions as external developers, so we do not represent the companies behind these platforms in any way. If you use a platform that is not listed below, please feel free to contact us and briefly introduce your system. We may also find a solution for you."
    },
    platform:{
      title:"Packages",
      contact: "Feel free to contact us if you are in need of a solution to a use case which is not listed below.",
      request: "Request  solution"
    },
    detail:{
      back: "Back to Overview",
      download:{
        pdf: "Download PDF"
      }
    },
    dataprotection:{
      title: "Data Protection Declaration"
    },

    contact:{
      title: "Contact",
      company: "Company",
      email: "Email",
      phone: "Phone",
      message: "Message",
      sendMessage:"Send Message",
      info:{
        text: "You can reach us with following options:",
      },
    },
    usu:{
      pdfexport:{
        interface:{
          title:"PDF-Export by GenericInterface",
          info:"Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
          detail: {
            title:"PDF-Export by GenericInterface",
            info:"Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor."
          }
        },
        gui:{
          title:"Configurated PDF-Export in Catalog and Views",
          info: "Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
          detail: {
            title:"PDF-Export by GenericInterface",
            info:"Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor."
          }
        }
      }
      },
      navbar: {
        packages:"Packages",
        home: "Home",
        contact: "Contact"
      },
      footer:{
        contact:"Contact",
        imprint: "Imprint",
        data: "Data protection"
      },
      home: {
        title:{
          part1: "Your Partner ",
          part2: "in ITSM"
        },
        info:{
          consulting: {
            title: "Consulting",
            text: "We help you analyze and optimize existing processes.\n If necessary we will develop new processes and accompany you during the initial stages."
          },
          support:{
            title: "Support",
            text: "We will gladly support you with your ITSM software tools or systems.\nWether its introduction, adjustments or maintenance."
          },
          solutions:{
            title: "Solutions",
            text: "If you have use cases that require solutions, feel free to contact us."
          },
          info: "You can reach us per mail with info{'@'}dr-husmann.de or by using the form.",
          contactBtn: 'Contact'
        }
      }
  },
  de: {
    platforms:{
      title:"Pakete",
      subtitle:"Für welche Plattform suchen Sie Erweiterungen?",
      info:"Unabhängig von Einzellösungen bieten wir Erweiterungen für verschiedene Plattformen an. Wir entwickeln diese Lösungen als externe Entwickler, sodass wir die Unternehmen hinter diesen Plattformen in keiner Weise repräsentieren. Falls Sie eine Plattform, die unten nicht aufgeführt ist, benutzen, können Sie uns gerne kontaktieren und ihr System kurz vorstellen. Eventuell finden wir auch für Sie eine Lösung."
    },
    platform:{
      title:"Pakete",
      contact: "Falls Sie kein Paket, das sich mit ihrer Anforderung beschäftigt, finden, können Sie uns gerne kontaktieren.",
      request: "Erweiterung anfragen"
    },
    detail:{
      back: "Zurück zur Übersicht",
      download:{
        pdf: "PDF herunterladen"
      }
    },
    dataprotection:{
      title: "Datenschutzerklärung"
    },
    imprint:{
      title:"Impressum"
    },
    contact:{
      title: "Kontakt",
      company: "Firma",
      email: "Email",
      phone: "Telefon",
      message: "Nachricht",
      sendMessage:"Nachricht senden",
      info:{
        text: "Sie können uns auf den folgenden Wegen erreichen:",

      }
    },
    usu:{
      pdfexport:{
        interface:{
          title:"PDF-Export für GenericInterface",
          info:"Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
          detail:{
            title:"PDF-Export für GenericInterface",
            info:"Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
            
          }
        },
        gui:{
          title:"Konfigurierter PDF-Export für Kataloge und Ansichten",
          info: "Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
          detail: {
            title:"Konfigurierter PDF-Export für Kataloge und Ansichten",
            info: "Nunc malesuada lobortis quam sed ullamcorper. Nullam nec quam ut magna auctor sodales a sollicitudin metus. Nunc faucibus mauris eget iaculis vulputate. Integer scelerisque vitae orci ac efficitur. Curabitur ultricies eget lorem eget congue. In lobortis consequat turpis, in vestibulum ex vehicula at. In eget faucibus urna, ut ultricies felis. Cras hendrerit tellus eget orci porta, vitae egestas felis molestie. Nam sed tortor ultrices neque bibendum tempor.",
          
          }
        }
      }
    },
    navbar: {
      packages:"Pakete",
      home: "Home",
      contact: "Kontakt"
    },
    footer:{
      contact:"Kontakt",
      imprint: "Impressum",
      data: "Datenschutz"
    },
    home: {
      title:{
        part1: "Ihr Partner im",
        part2: "Bereich ITSM"
      },
      info:{
        consulting: {
          title: "Prozessberatung",
          text: "Wir helfen Ihnen bei der Analyse und Optimierung von bestehenden Prozessen.\nBei erforderlichen Erweiterungen entwickeln wir neue Prozesse mit Ihnen und begleiten die Einführung."
        },
        support:{
          title: "Unterstützung",
          text: "Bei Tools oder Systemen, die Sie in ihren Arbeitsabläufen verwenden, unterstützen wir Sie gerne.\nOb Neueinführung, Anpassungen oder Wartung spielt hierbei keine Rolle."
        },
        solutions:{
          title: "Lösungen",
          text: "Wenn Sie allgemeine IT-Lösungen suchen stehen wir Ihnen auch zur Verfügung. Zum Beispiel Software-Lösungen im Bereich Schnittstellen oder Plugins."
        },
        info: "Sie können direkt per Email mit info{'@'}dr-husmann.de oder per Onlineformular mit uns Kontakt auf nehmen.",
        contactBtn: 'Kontakt aufnehmen'
      }
    }
  }
};

export {messages};