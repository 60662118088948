<template>
    <div v-if="platformLoaded" class="d-flex">
        <img  :src="platform.temp" class="img-fluid" style="width:12%"> <h4 class="display-4 align-self-center">- {{ $t('platform.title') }}</h4>
    </div>
    <div class="d-flex align-items-center">
                    <div class="p-2">
                    </div>    
    </div>
    <div class="container d-flex align-items-center justify-content-center">
        <div class="text-center">
            {{ $t('platform.contact') }}
        </div>
        <div class="p-2">
            <button class="btn btnCustom" @click="redirectToContact">{{ $t('platform.request') }}</button>
        </div>
    </div>
    <div v-if="false" class="container d-flex justify-content-between mt-2">
        <span class="text-center"  style="font-size:1.5rem;margin-left: 2%;">Filter</span>
        <input type="text" placeholder="Bitte geben Sie einen Filterbegriff ein" class="form-control" style="margin-left: 2%;margin-right: 1%;">
        <input type="text" placeholder="Bitte geben Sie einen zusätzlichen Filterbegriff ein" class="form-control" style="margin-right: 10%;margin-left: 1%;">
    </div>
    <div class="row row-cols-1 row-cols-md-2 g-4 mt-4 ">
        <PackageRowComponent  v-for="singlePackage in platform.packages" @redirectToPackageDetail="redirectToPackageDetail" :key="singlePackage.id" :packageId="singlePackage.id" :title="singlePackage.title" :info="singlePackage.info" :image="singlePackage.image" ></PackageRowComponent>
    </div>

</template>
<script>
import PackageRowComponent from '@/components/PackageRowComponent.vue';
import { mapActions } from 'vuex'


export default{
    name: "PackagesPlatformView",
    components: { PackageRowComponent},
    
    props:{
    },
    data(){
        return {
            image: "",
            platform: {}
           
        }
    },
    created(){
        let platformId = this.$route.params.platformId
        this.updateSelectedPlatformById(platformId)
      
        this.platform = this.$store.getters.selectedPlatform;
        
        
    },
    methods:{
        ...mapActions(['updateSelectedPlatformById']),
       
        redirectToContact(){
            this.$router.push({ path: '/contact' }); 
        },
        redirectToPackageDetail(packageId){
            let newPath = this.$route.path + "/"+packageId
            console.log("TEST PATHING DETAIL")
            this.$router.push({ path: newPath}); 
        },
        getImgUrl(pet) {
            var images = require.context('@/assets/', false, /\.svg$/)
            return images('./' + pet)
    }
        
    },
    computed:{
        platformLoaded(){
            return 'id' in this.platform
        },
        
        searchOptions(){
            var options = [];
            for (const singlePackage of this.packages) {
                options.push(singlePackage.title)
            }
            return options;
            }   
        },
       
}
</script>
<style>

</style>