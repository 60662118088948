<template>
    <div class="container-fluid">
        <div class="row container-fluid">
            <div class="col">
                <InfoBoxComponent :title="$t('home.info.consulting.title')" :info="$t('home.info.consulting.text')"><img src="@/assets/consulting.svg" class="img-fluid" ></InfoBoxComponent>
            </div>
            <div  class="col">
                <InfoBoxComponent :title="$t('home.info.support.title')" :info="$t('home.info.support.text')" ><img src="@/assets/support.svg" class="img-fluid" ></InfoBoxComponent>
            </div>
        </div>
        <div class="row container-fluid" style="margin-top: 2%;">
            
            <div  class="col-6  offset-md-3">
                <InfoBoxComponent :title="$t('home.info.solutions.title')" :info="$t('home.info.solutions.text')" ><img src="@/assets/solutions.svg" class="img-fluid"></InfoBoxComponent>
            </div>
            
        </div>
    </div>
</template>
<script>
import InfoBoxComponent from './InfoBoxComponent.vue';
export default{
    name:"InfoLineComponent",
    components: {
        InfoBoxComponent
    },
    computed: {
    
    }
}
</script>
<style>
.infoline{
    display: flex;
    flex-direction: row;
    align-items: center;
}
</style>