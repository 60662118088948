<template>
    <div>
        <div v-if="isMobile">
            <ContactMobileView></ContactMobileView>
        </div>
        <div v-else>
            <h3 class="display-3">{{$t('contact.title')}}</h3>
            <div class="d-flex ">
            <!--    <div class="flex-grow-1">
                    <form class="p-3" style="font-size:1.5rem;">
                        <div class="mb-2" style="margin-right:50%;">
                            <label for="contactCompany" class="form-label">{{$t('contact.company')}}</label>
                            <input v-model="company"  type="text" class="form-control" id="contactCompany" placeholder="">
                        </div>
                        <div class="mb-2" style="margin-right:50%;">
                            <label for="contactEmail" class="form-label">{{$t('contact.email')}}</label>
                            <input v-model="email" type="email" class="form-control" id="contactEmail" placeholder="">
                        </div>
                        <div class="mb-2" style="margin-right:50%;">
                            <label for="contactPhone" class="form-label">{{$t('contact.phone')}}</label>
                            <input type="text" class="form-control" id="contactPhone" placeholder="">
                        </div>
                        <div class="mb-3" style="margin-right:30%;">
                        <label for="exampleFormControlTextarea1" class="form-label">{{$t('contact.message')}}</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholer=""></textarea>
                        </div>
                        <div>
                            <button class="btn btnCustom" @click="sendContact()">{{$t('contact.sendMessage')}}</button>
                        </div>
                    </form>
                </div>-->
                <div class="" style="font-size:1.5rem;padding-top:10%;padding-right:10%">
                    <span>{{$t('contact.info.text')}}</span><br><br>
                    <span style="padding-left:20%"><b>{{$t('contact.email')}}</b>: info@dr-husmann.de</span><br>
                    <span style="padding-left:20%"><b>{{$t('contact.phone')}}</b>: +49 (0) 172 / 4011025</span>
                </div>
            </div>
            
        </div>
    </div>
    
</template>
<script>
import ContactMobileView from '@/views/mobile/ContactMobileView.vue';

import axios from 'axios';
export default{
    name:"ContactView.vue",
    components:{
        ContactMobileView
    },
    data(){
        return {
            company: "",
            phone: "",
            email: "",
            message: "",
            windowWidth: window.innerWidth
        }
    },
    mounted() {
      window.onresize = () => {
          this.windowWidth = window.innerWidth
      }
    },
    computed: {
        isMobile() {
            let isSmartphone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            let isXS =  this.windowWidth <= 576
            
            return isXS || isSmartphone
        }
    },
    methods:{
        sendContact(){
           
            axios.post('/api/sendMail.php', {
                    message: this.message,
                    phone: this.phone,
                    email: this.email,
                    company: this.company
            }).then(response => {
                console.log("SUCCESS")
                console.log(response);
            }).catch(err => {
                console.error(err);
            });

        }
    }

}
</script>
<style>
</style>