<template>
    <div>   
        <nav class="navbar navbar-expand-sm navbar-light py-1 py-sm-1 p-2 dark-text bg-white" style="height:3%">
            <div class="container-fluid">
                <LogoComponent></LogoComponent>
                <div class="navbar-collapse p-2" id="navbarSupportedContent" style="margin-right: 2%;">
                    <ul class="nav navbar-nav w-100 justify-content-end p-10">
                        <li v-if="isNotLandingPage" class="nav-item" style="margin-right:1%"> 
                            <button class="btn btn-lg btnCustom" @click="redirectToLandingPage()">{{ $t('navbar.home') }}</button>
                        </li>
                        <li v-if="isNotPackagesLanding" class="nav-item" style="margin-right:1%" >
                            <button class="btn btn-lg btnCustom" @click="redirectToProducts()">{{ $t('navbar.packages') }}</button>
                        </li>
                        <li v-if="isNotContact" class="nav-item" >
                            <button class="btn btn-lg btnCustom" @click="redirectToContact()">{{ $t('navbar.contact') }}</button>
                        </li>
                    </ul> 
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import LogoComponent from './LogoComponent.vue';
export default {
    name: "NavComponent",
    components: {
        LogoComponent
    },
    methods:{
        
        redirectToLandingPage(){
            
            this.$router.push({ path: '/' });
        },
        redirectToProducts(){
           
            this.$router.push({ path: '/packages' });
        },
        redirectToContact(){
            
            this.$router.push({ path: '/contact' }); 
        }
    },
    computed:{
        isNotLandingPage(){
            return this.$route.path != "/";
        },
        isNotPackagesLanding(){
            return this.$route.path != "/packages"
        },
        isNotContact(){
            return this.$route.path != "/contact";
        },
       
        platform(){
            return this.$store.getters.platform
        },
        selectedPackage(){
            return this.$store.getters.selectedPackage
        },
        
    }
}
</script>
<style scoped>



</style>