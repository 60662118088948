<template>
    <div class="col-sm-3">
                    <img :src="require(`../assets/${img}`)" class="img-fluid imgSelect" @click="redirectToUSUPlatform()">
    </div>
</template>
<script>
export default{
    name:"PlatformSelectionLogo",
    props:{
        img :String,
        platformId: String
    },
    methods: {
        redirectToUSUPlatform() {
            let path = "packages/" + this.platformId ;
            this.$router.push({ path: path });
        }
    }
}
</script>
<style>


.imgSelect:hover {
  animation-name: bubble;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  cursor: pointer;
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>