<template>
    <div class="d-flex text-dark" style="">
            <div class="align-self-center" style="margin-left:1%;width:85%">
                <img class="align-text-top" src="@/assets/logo.svg" alt="" style="float:left; display:inline; height:4em; width:auto; transform:translate(0, 0.1em)" >
                <h3 class="align-self-top align-self-center" style="margin-top:0.3em;margin-left:1%;margin-bottom:0%;float:left; display:inline; width: 49%;">
                    Ingenieurbüro <br> Dr.Husmann
                </h3>
            </div>
            <div class="align-self-center dropdown ml-auto" style="width:15%">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <BIconList style="font-size: 2rem;"></BIconList>
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li v-if="isNotLandingPage"><span class="dropdown-item" @click="redirectToLandingPage()">{{ $t('navbar.home') }}</span></li>
                    <li v-if="isNotPackagesLanding"><span class="dropdown-item" @click="redirectToProducts()" >{{ $t('navbar.packages') }}</span></li>
                    <li v-if="isNotContact"><span class="dropdown-item"  @click="redirectToContact()">{{ $t('navbar.contact') }}</span></li>
                    <li><hr class="dropdown-divider"></li>
                    <li v-if="isGerman"><span class="dropdown-item" @click="changeLanguage('en')">English</span></li>
                    <li v-if="isEnglish"><span class="dropdown-item" @click="changeLanguage('de')">Deutsch</span></li>
                </ul>
            </div>
    </div>
</template>
<script>
import { BIconList } from 'bootstrap-icons-vue';
export default{
    name:"HeaderMobileComponent",
    components:{
        BIconList
    },
    computed: {
        isGerman() {
            return this.$i18n.locale == 'de';
        },
        isEnglish() {
            return this.$i18n.locale == 'en';
        },
        isNotLandingPage(){
            return this.$route.path != "/";
        },
        isNotPackagesLanding(){
            return this.$route.path != "/packages"
        },
        isNotContact(){
            return this.$route.path != "/contact";
        },
    },
    methods:{
        changeLanguage(code){
            this.$i18n.locale = code
        },
        redirectToLandingPage(){
            
            this.$router.push({ path: '/' });
        },
        redirectToProducts(){
           
            this.$router.push({ path: '/packages' });
        },
        redirectToContact(){
            
            this.$router.push({ path: '/contact' }); 
        }
    }
}
</script>
<style>
</style>