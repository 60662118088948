<template>
    <div>
        <h3 class="display-3">{{$t('contact.title')}}</h3>
        <div class="d-flex ">
           <!--- <div class="flex-grow-1">
                <form class="p-3" style="font-size:1.5rem;">
                    <div class="mb-2" style="margin-right:50%;">
                        <label for="contactCompany" class="form-label">{{$t('contact.company')}}</label>
                        <input v-model="company"  type="text" class="form-control" id="contactCompany" placeholder="">
                    </div>
                    <div class="mb-2" style="margin-right:50%;">
                        <label for="contactEmail" class="form-label">{{$t('contact.email')}}</label>
                        <input v-model="email" type="email" class="form-control" id="contactEmail" placeholder="">
                    </div>
                    <div class="mb-2" style="margin-right:50%;">
                        <label for="contactPhone" class="form-label">{{$t('contact.phone')}}</label>
                        <input type="text" class="form-control" id="contactPhone" placeholder="">
                    </div>
                    <div class="mb-3" style="margin-right:30%;">
                    <label for="exampleFormControlTextarea1" class="form-label">{{$t('contact.message')}}</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholer=""></textarea>
                    </div>
                    <div>
                        <button class="btn btnCustom">{{$t('contact.sendMessage')}}</button>
                    </div>
                </form>
            </div>-->
            <div class="text-center" style="font-size:1.5rem;padding-top:5%;padding-right:10%">
                {{$t('contact.info.text')}}<br><br>
                <b>{{$t('contact.email')}}</b><br> info@dr-husmann.de<br>
                <b>{{$t('contact.phone')}}</b><br> +49 (0) 172 / 4011025
            </div>
        </div>
        
    
    </div>
    
</template>
<script>
import axios from 'axios';
export default{
    name:"ContactMobileView.vue",
    methods:{
        sendContact(){
           
           axios.post('/api/sendMail.php', {
                   message: this.message,
                   phone: this.phone,
                   email: this.email,
                   company: this.company
           }).then(response => {
               console.log("SUCCESS")
               console.log(response);
           }).catch(err => {
               console.error(err);
           });

       }
    }
}
</script>
<style>
</style>