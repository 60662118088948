<template>
    <div class="">
        <div class="">
          <!--  <img :src="image" style="display:inline-block; height:1em; width:auto; transform:translate(0, 0.1em)"> {{ $t(title)}}-->
          <h3>{{ $t(title)}}</h3>
        </div>
        <div class="">
            {{ $t(info)}}
        </div>
    </div>
</template>
<script>
export default{
    name:"InfoBoxMobileComponent",
    props:{
        title:String,
        info: String,
        image: String
    },
    computed:{
        
    }
}
</script>
<style>
</style>