<template>
    <div class="col dark-text" style="margin-top: 2%;">
        <div class="inner w-100 h-100 border">
        <div class="row w-100 h-100">
            <div class="col align-self-center packageImg">
                <img :src="require(`../assets/${image}`)" class="img-fluid" style="margin-left:10%">
            </div> 
            <div class="col-8 flex-fill additionalPadding" style="padding-right:0%;">
                <div class="d-flex" style="margin-top:2%;">
                    <div class="w-100 smallerTitleFontSize"><h3>{{  $t(title) }}</h3></div>
                    <div class="flex-shrink-1">
                        <button class="btn btnCustom" v-on:click="$emit('redirectToPackageDetail', packageId)">Details</button>
                    </div>
                </div>
                <span style="font-size:auto;">{{ $t(info) }}</span>
            </div> 
        </div>
        </div>
    </div>
</template>
<script>

export default{
    name:"PackageRowComponent",
    props:{
        packageId: String,
        title:String,
        info: String,
        btn: String,
        image: String
    },
    emits: ['redirectToPackageDetail'],
    method:{
        
    
    },
    computed:{
       
    }
}
</script>
<style>
.inner{

  border: 1px;
}

@media only screen and (max-width: 1026px) {
    .packageImg {
        display: none;
    }
    .additionalPadding{
        padding-left:5%;
    }
    .smallerTitleFontSize{
        font-size: medium;
    }
    
}
</style>