<template>
    <div class="">
        <button class="btn btn-md btnCustom" @click="redirectToPlatform">{{$t('detail.back')}}</button>
        <h3 class="display-4">{{ $t(selectedPackage.detail.title) }} </h3>
        
        <div class="mt-3">
            {{  $t(selectedPackage.detail.info)}}
        </div>
        <div style="margin-left:45%;margin-top:2%;">
            <button class="btn btn-md btnCustom" @click="downloadDetailPDF">{{$t('detail.download.pdf')}}</button>
            <!--<a :href="@/files/${selectedPackage.detail.pdf}" download> >PDF herunterladen </a>-->
        </div>
        <div class="flex-grow-1">

        </div>
         <!--<div  class="" style="margin-left:4%;margin-right:4%;margin-top: 4%;margin-bottom: 4%;">
            <PackageDetailSlider :images="selectedPackage.detail.images"></PackageDetailSlider>
        </div>-->
        
    </div>
</template>
<script>
import { mapActions } from 'vuex'
//import PackageDetailSlider from '@/components/PackageDetailSlider.vue'

export default{
    name:"PackageDetailView",
    components:{
       // PackageDetailSlider
    },
    data(){
        return {
            selectedPackage:{}
        }   
    },
    methods:{
        ...mapActions(['updateSelectedPackageByPackageIdAndPlatformId']),
        
        redirectToPlatform(){
            let platformId = this.$route.params.platformId
            let newPath = "/packages" + "/"+ platformId        


            this.$router.push({ path: newPath}); 
            return this.$route.path != "/packages";
        }
    },
    created(){
        
        let platformId = this.$route.params.platformId
        let packageId = this.$route.params.packageId
        
        let ids = {
            platformId: platformId,
            packageId: packageId
        }
        this.updateSelectedPackageByPackageIdAndPlatformId(ids)
        this.selectedPackage = this.$store.getters.selectedPackage
        console.log("DETAIL CREATE")
        console.log(this.selectedPackage)
        console.log(this.selectedPackage.detail.images)
        
    },
    computed:{
        
    }
    
}
</script>
<style>
</style>