<template>
  <div>
    <div v-if="isMobile">
      <MobileLayout>
        <router-view></router-view>
      </MobileLayout>
    </div>
    <div v-else>
      <DefaultLayout>
        <router-view></router-view>
      </DefaultLayout>
    </div>
  </div>     
</template>

<script>
import DefaultLayout from './layouts/DefaultLayout.vue';
import MobileLayout from './layouts/MobileLayout.vue';
export default {
  name: 'App',
  title: 'Ingenieurbüro Dr.Husmann',
  components: {
      DefaultLayout,
      MobileLayout
  },
  data() {
            return {
                windowWidth: window.innerWidth
            }
    },
  mounted() {
      document.title = "Ingenieurbüro Dr.Husmann";
      window.onresize = () => {
          this.windowWidth = window.innerWidth
      }
  },
  computed:{
        isMobile() {
            let isSmartphone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            let isXS =  this.windowWidth <= 576

            return isXS || isSmartphone
        }
    }
        };
    
</script>

<style>

.btnCustom {
    color: gray-900;
    background-color: #ffb03a;
    border-color: #ffb03a; /*set the color you want here*/
}
.btnCustom:hover, .btnCustom:focus, .btnCustom:active, btnCustom.active, .open>.dropdown-toggle.btn-primary {
    color: gray-900;
    background-color: lightgray;
    border-color: lightgray; /*set the color you want here*/
}




</style>
