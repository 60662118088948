<template>
    <div>
        <div>
            <h1 class="display-1 text-dark">
                <!-- TODO use grid system instead of &nbsp;-->
                <div class="text-left">
                    {{$t('home.title.part1')}}
                </div>
                <div class="text-right" style="padding-left: 12%;">
                    {{$t('home.title.part2')}}
                </div>  
            </h1>    
        </div>
    
        <div class="mt-4">
            <InfoBoxMobileComponent class="mt-2" v-for="infobox in infoboxes" :key="infobox.id" :title="infobox.title" :info="infobox.info" :image="infobox.image"></InfoBoxMobileComponent>
        </div>
    </div>
</template>
<script>
import InfoBoxMobileComponent from '@/components/mobile/InfoBoxMobileComponent.vue'
export default{
    name:"HomeMobileView",
    components:{
        InfoBoxMobileComponent
    },
    data(){
        return {
            infoboxes: []
           
        }
    },
    created(){
        this.infoboxes = this.$store.getters.infoboxes;

    },
}</script>
<style></style>