<template>
    <footer class="h-4 fixed-bottom dark-text" style="background-color: lightgray"><!--fixed-bottom -->
        <div class="d-flex">
                    <div class="col-3 p-2">
                        © 2023 Copyright:
                        <a  class="customLink" href="">Ingenieurbüro Dr.Husmann</a>
                    </div> 
                    <div class="col-2 p-2">
                    </div>
                    <div class="col-3 p-2">
                        <router-link class="customLink" style="margin-right:3%" to="/imprint">{{ $t('footer.imprint') }}</router-link>
                        <router-link class="customLink" style="margin-right:3%" to="/dataprotection">{{ $t('footer.data') }}</router-link> 
                        <router-link class="customLink" to="/contact">{{ $t('footer.contact') }}</router-link>  
                    </div>
                    <div class="col-3 p-2">
                    </div>
                    <div>
                        <LanguageSelectComponent ></LanguageSelectComponent>
                    </div>
                   
                    
        </div>
        
</footer>
</template>
<script>
import LanguageSelectComponent from './LanguageSelectComponent.vue';

export default{
    name: "FooterComponent",
    components: { LanguageSelectComponent }
}
</script>
<style>
.customLink{
    color: inherit;
}
</style>