<template>
    <div>
        <div v-if="isMobile">
            <HomeMobileView></HomeMobileView>
        </div>
        <div v-else>
            <div class="">
                <h1 class="display-1 text-dark">
                    <!-- TODO use grid system instead of &nbsp;-->
                    <div class="text-left">
                        {{$t('home.title.part1')}}
                    </div>
                    <div class="text-right" style="padding-left: 12%;">
                        {{$t('home.title.part2')}}
                    </div>
                
                </h1>    
            </div>
            <div  style="margin-top:3.5%">
                <InfoLineComponent></InfoLineComponent>  
            </div>
            <div class="text-center dark-text" style="margin-top:2%;white-space: pre;">
                <div style="margin-top:1%">
                    <ContactComponent :text="$t('home.info.contactBtn')"></ContactComponent>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoLineComponent from "./../components/InfoLineComponent.vue"
import ContactComponent from '@/components/ContactComponent.vue';
import HomeMobileView from '@/views/mobile/HomeMobileView.vue';
export default{
    name: "HomeView",
    
    components: {   
        HomeMobileView,
        InfoLineComponent,
        ContactComponent
    },
    data() {
            return {
                windowWidth: window.innerWidth
            }
    },
    mounted() {
      window.onresize = () => {
          this.windowWidth = window.innerWidth
      }
    },
    computed: {
        isMobile() {
            let isSmartphone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            let isXS =  this.windowWidth <= 576
            
            return isXS || isSmartphone
        }
    },
   
}
</script>
<style>



</style>