<template>
    <div class="d-flex justify-content-between text-center p-2 ">
        <span v-bind:class="{ languageSelected: 'de' == getLangCode() }" class="languageChoice" @click="changeLanguage('de')">Deutsch</span>
        <span v-bind:class="{ languageSelected: 'en' == getLangCode() }" class="languageChoice" @click="changeLanguage('en')" style="margin-left:6%">English</span>
    </div>
</template>
<script>
export default{
    name:"LanguageSelectComponent",
    methods:{
        changeLanguage(code){
            this.$i18n.locale = code
        },
        getLangCode(){
            return this.$i18n.locale
        }
    }

}
</script>
<style scoped>
.languageSelected {
    text-decoration: underline;
}

.languageChoice:hover{
    cursor: pointer;
}
</style>