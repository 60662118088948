<template>
    <ImprintDE v-if="isGerman"></ImprintDE>
    <ImprintEN v-else></ImprintEN>
</template>
<script>
import ImprintDE from '@/components/ImprintDE.vue';
import ImprintEN from '@/components/ImprintEN.vue';

export default{
    name: "ImprintView.vue",
    components:{ImprintDE,ImprintEN},
    computed: {
        isGerman() {
            return this.$i18n.locale == 'de';
        },
        isEnglish() {
            return this.$i18n.locale == 'en';
        }
    }
}
</script>