import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"

import { createApp } from 'vue'
import App from './App.vue'



import {store} from './store/store.js'
import {router} from './router/router.js'
// eslint-disable-next-line
import Select2 from 'vue3-select2-component';

import {i18n} from './i18n/i18n.js'

const app = createApp(App);

app.use(i18n)
app.use(store)
app.use(router)

// eslint-disable-next-line
app.component('Select2', Select2)

app.mount('#app');

