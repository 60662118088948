<template>
    <header > <!--class="fixed-top"-->
        <NavComponent></NavComponent>
    </header>
</template>
<script>
import NavComponent from './NavComponent.vue';

export default{
    name: "HeaderComponent",
    components: {
        NavComponent
    },
    
}
</script>
<style scoped>

</style>