import {createRouter,createWebHistory} from 'vue-router'

import ContactView from '@/views/ContactView.vue'
import DataProtectionView from '@/views/DataProtectionView.vue'
import ImprintView from '@/views/ImprintView.vue'
import PackagesLandingView from '@/views/PackagesLandingView.vue'
import HomeView from '@/views/HomeView.vue'
import PackagesPlatformView from '@/views/PackagesPlatformView.vue'
import PackageDetailView from '@/views/PackageDetailView.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/contact', component: ContactView },
  { path: '/dataprotection', component: DataProtectionView},
  { path: '/imprint', component: ImprintView},
  { path: '/packages', component: PackagesLandingView},
  { path: '/packages/:platformId', component: PackagesPlatformView},
  { path: '/packages/:platformId/:packageId', component: PackageDetailView},
  { path: '/*', component: HomeView },
];
const router = createRouter({
    history:createWebHistory(),
    routes, // short for `routes: routes`
  });
  
export {router};

