<template>
    <footer class="h-4 text-center" style="background-color: lightgray"><!--fixed-bottom -->
        <div class="align-self-center" style="display: flex;flex-direction: column;">
            <router-link class="customLink" style="margin-top:3%;" to="/imprint">{{ $t('footer.imprint') }}</router-link>
            <router-link class="customLink" style="margin-top:1%;" to="/dataprotection">{{ $t('footer.data') }}</router-link> 
            <router-link class="customLink" style="margin-top:1%;" to="/contact">{{ $t('footer.contact') }}</router-link>  
            <div class="p-2">
                        © 2023 Copyright: <a  class="customLink" href="">Ingenieurbüro Dr.Husmann</a>
            </div> 
        </div>
    </footer>
           
</template>
<script>
export default{
    name:"FooterMobileComponent"
}
</script>
<style>
</style>