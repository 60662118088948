<template>
    <div class="app dark-text">
        <HeaderMobileComponent></HeaderMobileComponent>
        <div style="margin-bottom:5% ">
            <div class="view">
                <slot></slot>
            </div> 
        </div>
        <FooterMobileComponent></FooterMobileComponent>
    </div>
</template>
<script>
import HeaderMobileComponent from '@/components/mobile/HeaderMobileComponent.vue'
import FooterMobileComponent from '@/components/mobile/FooterMobileComponent.vue'
export default{
    name:"DefaultLayout",
    components:{
        HeaderMobileComponent,
        FooterMobileComponent
       
    }
}
</script>
<style>
#top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 23px;
}
#app {
  background-color: rgb(255,255,255);
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #2c3e50;
  margin-top: 2%;
}

.view {
  height: 90%;
  margin-top: 2%;
  margin-right: 5%;
  margin-left: 5%;
}
</style>