<template>
    <div class="container text-dark">
        <div class="row">
            <div class="col-md-3">
                <div style="padding:20%">
                    <slot></slot>
                </div>
            </div>
            <div class="col my-auto">
                <h3 >{{ title }}</h3>    
                <span style="font-size:20px">{{ info }}</span>
            </div>   
        </div>
    </div>
</template>
<script>

export default{
    name:"InfoBoxComponent",
    props:{
        title:String,
        info: String,
        btn: String,
        image: String
    }
}
</script>
<style>

</style>