import { createStore } from 'vuex'


const store = createStore({
    state () {
      return {
        selectedPlatform: {},
        selectedPackage: {},
        infoboxes:[
          {
            id:1,
            title: "home.info.consulting.title",
            info: "home.info.consulting.text",
            image: "https://dr-husmann.de/img/consulting.23c6b612.svg"
          },
          {
            id:2,
            title: "home.info.support.title",
            info: "home.info.support.text",
            image: "https://dr-husmann.de/img/support.0ad64a9b.svg"
          },
          {
            id:3,
            title: "home.info.solutions.title",
            info: "home.info.solutions.text",
            image: "https://dr-husmann.de/img/solutions.06819bc0.svg"
          }
        ],
        platforms: [
          {
            id: "1",
            name: "USU IT-Service Management",
            temp: "https://dr-husmann.de/img/USU_Logo.05d73952.svg",
            image: "USU_Logo.svg",
            packages: []
          }
        ],
        packages:[
            {
              platformId: "1",
              packages: [
                   /* { 
                      id: "1",
                      title: 'usu.pdfexport.interface.title',
                      info: 'usu.pdfexport.interface.info',
                      image: 'pdf-files-icon.svg',
                      detail: {
                          title: 'usu.pdfexport.interface.detail.title',
                          info: 'usu.pdfexport.interface.detail.info',
                          pdf: 'usu/pdfexport/interface/pdfexport_interface_info.pdf',
                          images: [
                            {
                              id:1,
                              image:'pdfexport/interface/1.png'
                            },
                            {
                              id:2,
                              image:'pdfexport/interface/2.png'
                            }
                          ]
                      }
                    },
                    {
                      id: "2",
                      title: 'usu.pdfexport.gui.title',
                      info: 'usu.pdfexport.gui.info',
                      image: 'pdf-files-icon.svg',
                      detail:{
                          title: 'usu.pdfexport.gui.detail.title',
                          info: 'usu.pdfexport.gui.detail.info',
                          pdf: 'usu/pdfexport/interface/pdfexport_gui_info.pdf',
                          images: [
                            {
                              id:1,
                              image:'pdfexport/gui/1.png'
                            },
                            {
                              id:2,
                              image:'pdfexport/gui/2.png'
                            }
                          ]
                      }
                    } 
                  */]
              }
        ]
      }

    },
    getters:{
        infoboxes: state => {
          return state.infoboxes
        },
          selectedPlatform: state => {
           return state.selectedPlatform
         },
          selectedPackage: state => {
            return state.selectedPackage
        },
        platforms: state => {
          return state.platforms
        }
    },
    mutations: {
        updateSelectedPlatform(state, platform) {
          state.selectedPlatform = platform
        },
        updateSelectedPackage(state, selectedPackage) {
            state.selectedPackage = selectedPackage
        },
        updateSelectedPlatformById(state, platformId){
          for (const platform of state.platforms) {
            if(platform.id == platformId){
              for(const selectedPackages of state.packages){
                if(selectedPackages.platformId == platformId){
                  state.selectedPlatform = platform
                  state.selectedPlatform.packages = selectedPackages.packages
                }
              }
            }
          }
        },
        updateSelectedPackageByPackageIdAndPlatformId(state, ids){

          let packageId = ids.packageId;
          let platformId = ids.platformId;

          for (const platform of state.platforms) {
            
           
            if(platform.id == platformId){
              for(const selectedPackages of state.packages){
                
                if(selectedPackages.platformId == platformId){
                  state.selectedPlatform = platform
                  state.selectedPlatform.packages = selectedPackages.packages
                }
              }
            }
          }
          
          for (const currentPackage of state.selectedPlatform.packages) {
                if(currentPackage.id == packageId){
                  state.selectedPackage = currentPackage;
                }
              }
          }
          
    },
      actions: {
        
        updatePlatform({ commit }, platform) {
          commit('updatePlatform', platform)
        },
        updateSelectedPackage({ commit }, selectedPackage) {
            commit('updateSelectedPackage', selectedPackage)
        },
        updateSelectedPlatformById({ commit }, platformId) {
          commit('updateSelectedPlatformById', platformId)
        },
        updateSelectedPackageByPackageIdAndPlatformId({ commit }, ids) {
          
          commit('updateSelectedPackageByPackageIdAndPlatformId', ids)
        } ,
      }
  })

  export {store}